<!--
 * @Author: 苏航
 * @Date: 2021-07-25 16:53:39
 * @LastEditTime: 2021-07-25 21:32:42
 * @LastEditors: 苏航
 * @Description: 
 * @FilePath: /bareiss/src/components/parts-item/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <router-link
    :to="`/parts_details?id=${item.id}&status=${status}`"
    class="link"
  >
    <div class="imgBox">
      <img :src="item[imgKeyName]" alt="" srcset="" />
    </div>
    <div class="title">{{ item.e_title }}</div>
    <div class="name">{{ item.title }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    imgKeyName: {
      type: String,
      default() {
        return "";
      },
    },
    //跳转请求判断 1=配件 2=零件
    status: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  .imgBox {
    max-width: 210px;

    img {
      display: block;
      width: 100%;
    }
  }
  .title {
    font-size: 18px;
    color: #333;
    height: 36px;
    @include respond-to(max1366) {
      font-size: 14px;
    }
  }
  .name {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    height: 36px;
    @include respond-to(max1366) {
      font-size: 14px;
    }
  }

  &:hover {
    .title,
    .name {
      color: #0075c1;
    }
  }
}
</style>