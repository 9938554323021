<template>
  <div class="content">
    <div class="main">
      <div class="screen_view_wrap">
        <screen-view @change="change_screen" />
      </div>
      <section class="partsView">
        <template v-if="parts.length != 0">
          <div class="partsitem" v-for="(item, index) of parts" :key="index">
            <parts-item :item="item" img-key-name="attachment_url" status="1" />
          </div>
        </template>
        <empty-data icon-size="60px" font-size="16px" v-else />
      </section>
      <!-- <div class="line" v-if="spare.length != 0"></div>
      <section class="spareView" v-if="spare.length != 0">
        <h3>{{ $t("localization.parts.spare") }}</h3>
        <div class="swiperBox">
          <swiper
            :options="swiperOptionOne"
            ref="mySwiperOne"
            v-if="spare.length != 0"
          >
            <swiper-slide v-for="(item, index) in spare" :key="index">
              <div class="swiperItem">
                <parts-item
                  :item="item"
                  img-key-name="attachment_url"
                  status="2"
                />
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev swiperBtn" slot="button-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="swiper-button-next swiperBtn" slot="button-next">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </section> -->
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import screenView from "@/components/screen";
import partsItem from "@/components/parts-item";
export default {
  name: "Parts",
  components: {
    swiper,
    swiperSlide,
    screenView,
    partsItem,
  },
  data() {
    return {
      sample_id: 0, //样品id
      standard_id: 0, //标准id

      swiperOptionOne: {
        // loop: true,
        slidesPerView: 5,
        slidesPerColumn: 2,
        spaceBetween: 20,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, //允许分页点击跳转
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // on: {
        //   slideChangeTransitionEnd: () => {
        //     let mySwiper = this.$refs.mySwiperOne.swiper;
        //     if(mySwiper != null){
        //       console.log(mySwiper)
        //       let i = mySwiper.activeIndex;
        //       console.log(i)
        //     }
        //   },
        // },
      },
      parts: [],
      spare: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperOne.swiper;
    },
  },
  mounted() {
    this.getData();
    // this.sparePartsListFun();
  },
  methods: {
    /**
     * 筛选回调
     */
    change_screen(e) {
      this.sample_id = e.sample;
      this.standard_id = e.standard;
      this.getData();
    },
    //列表数据
    getData() {
      this.$http
        .parts({
          sample_id: this.sample_id,
          standard_id: this.standard_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.parts = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //零件
    sparePartsListFun() {
      this.$http.sparePartsList().then((res) => {
        if (res.code == 200) {
          this.spare = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/parts.scss";
</style>